import React, { useEffect, useState } from 'react';
import {
  Button as MaterialButton,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'gatsby-theme-material-ui';
import { navigate } from 'gatsby';
import * as API from '../../services/api';

const customerFields = [
  {
    key: 'uuid',
    label: 'ID',
  },
  {
    key: 'advertiserUrl',
    label: 'Advertiser Url',
  },
  {
    key: 'code',
    label: 'Advertiser Code',
  },
  {
    key: 'compute.formUrl',
    label: 'Advertiser Form Link',
  },
  {
    key: 'compute.pdfUrl',
    label: 'Advertiser Pdf Link',
  },
  {
    key: 'data.companyName',
    label: 'Company Name',
  },
  {
    key: 'data.companyEmail',
    label: 'Company Email',
  },
  {
    key: 'data.companyAddress',
    label: 'Company Address',
  },
  {
    key: 'data.CompanyVat',
    label: 'Company Vat',
  },
  {
    key: 'compute.sameBillingAddress',
    label: 'Same Billing Address',
  },
  {
    key: 'data.billingName',
    label: 'Billing Name',
  },
  {
    key: 'data.billingEmail',
    label: 'Billing Email',
  },
  {
    key: 'data.billingAddress',
    label: 'Billing Address',
  },
  {
    key: 'data.contactName',
    label: 'Contact Name',
  },
  {
    key: 'data.contactPhone',
    label: 'Contact Phone',
  },
  {
    key: 'data.contactEmail',
    label: 'Contact Email',
  },
  {
    key: 'compute.partnership',
    label: 'Partnership',
  },
  {
    key: 'compute.acceptTerms',
    label: 'Have Accepted The Terms',
  },
  {
    key: 'compute.haveAuthority',
    label: 'Have The Authority To Sign',
  },
  {
    key: 'data.signature',
    label: 'Signature',
  },
  {
    key: 'compute.cms',
    label: 'Cms',
  },
  {
    key: 'compute.formFinished',
    label: 'Form Finished',
  },
  {
    key: 'createdAt',
    label: 'Created At',
  },
  {
    key: 'updatedAt',
    label: 'Updated At',
  },
];

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),

    '& .MuiTypography-root': {
      flexGrow: 1,
    },
  },
  label: {
    width: '25%',
    maxWidth: '25%',
  },
  badgeYes: {
    display: 'inline-block',
    padding: '3px 5px 1px 5px',
    borderRadius: 4,
    color: 'white',
    background: '#46be8a',
    fontSize: '0.8em',
    letterSpacing: '0.15em',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  badgeNo: {
    display: 'inline-block',
    padding: '3px 5px 1px 5px',
    borderRadius: 4,
    color: 'white',
    background: '#dc4747',
    fontSize: '0.8em',
    letterSpacing: '0.15em',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
}));

export default function CustomersShow(props) {
  const { uuid } = props;
  const classes = useStyles();

  const [customer, setCustomer] = useState(null);

  const computeFields = (customer) => {
    const computePartnership = (partnership) => {
      switch (partnership) {
        case 'simple':
          return 'Only the agreed upon partnership with Ønskeskyen ApS.';
        case 'extended':
          return 'Also the Heylink exclusive partnership with BT, Aller and Klarna amongst others.';
        default:
          return 'None';
      }
    };

    const formUrl = `${process.env.CLIENT_HOST}/?code=${encodeURIComponent(
      customer.code
    )}`;
    const pdfUrl = `${process.env.BACKEND_HOST}/contract/${encodeURIComponent(
      customer.code
    )}.pdf`;
    const sameBillingAddress = !!customer.data.sameBillingAddress;
    const partnership = computePartnership(customer.data.partnership);
    const acceptTerms = !!customer.data.acceptTerms;
    const haveAuthority = !!customer.data.haveAuthority;
    const cms =
      customer.data.cms?.toLowerCase() === 'other'
        ? customer.data.cms_custom
        : customer.data.cms;
    const formFinished = !!customer.formFinished;

    customer.compute = {
      formUrl,
      pdfUrl,
      sameBillingAddress,
      partnership,
      acceptTerms,
      haveAuthority,
      cms,
      formFinished,
    };
  };

  useEffect(() => {
    API.getCustomer(uuid).then((newCustomer) => {
      if (newCustomer) {
        computeFields(newCustomer);
        setCustomer(newCustomer);
      }
    });
  }, [uuid]);

  if (!customer) {
    return <CircularProgress />;
  }

  const getFieldValue = (customer, key) => {
    if (key.indexOf('data.') === 0) {
      return customer.data[key.substr(5)];
    } else if (key.indexOf('compute.') === 0) {
      const value = customer.compute[key.substr(8)];
      if (typeof value === 'boolean') {
        return value ? (
          <div className={classes.badgeYes}>YES</div>
        ) : (
          <div className={classes.badgeNo}>NO</div>
        );
      } else if (/Url$/.test(key)) {
        return (
          <a href={value} target="_blank" rel="noreferrer">
            {value}
          </a>
        );
      } else {
        return value;
      }
    } else {
      return customer[key];
    }
  };

  const deleteCustomer = async () => {
    if (window.confirm('Are you sure?')) {
      await API.deleteCustomer(uuid);
      navigate('/signups');
    }
  };

  return (
    <>
      <Paper className={classes.nav}>
        <Typography variant="h6">Signup details</Typography>
        <Button color="primary" to={`/signups/${uuid}/edit`}>
          Edit
        </Button>
        <MaterialButton color="secondary" onClick={deleteCustomer}>
          Delete
        </MaterialButton>
      </Paper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {customerFields.map((field) => (
              <TableRow key={field.key}>
                <TableCell className={classes.label}>{field.label}</TableCell>
                <TableCell>{getFieldValue(customer, field.key)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
